// src/config.js

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const API_ENDPOINTS = {
    GURU_LOGIN: `${API_BASE_URL}/api/guru-login`,
    TODAY_PRESENCE: `${API_BASE_URL}/api/guru-todaypresence`,
    ADD_PRESENCE: `${API_BASE_URL}/api/guru-addpresensi`,
};

export default API_ENDPOINTS;
