import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoadScript } from '@react-google-maps/api'; // Removed GoogleMap import
import API_ENDPOINTS from './config'; // Import konfigurasi
import './Dashboard.css'; // Import file CSS

// API Key Anda
const API_KEY = 'YOUR_API_KEY';

// Koordinat referensi
const referenceLat = -6.9176888828555905;
const referenceLon = 107.70291999657314;

const Dashboard = () => {
    const [otp, setOtp] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [user, setUser] = useState(null);
    const [presenceData, setPresenceData] = useState(null);
    const [distance, setDistance] = useState(null);
    const [isCalculatingDistance, setIsCalculatingDistance] = useState(true);
    const [currentDateTime, setCurrentDateTime] = useState('');
    const navigate = useNavigate();

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: API_KEY,
        libraries: ['geometry'],
    });

    useEffect(() => {
        // Fetch user data from localStorage
        const userData = JSON.parse(localStorage.getItem('user'));
        setUser(userData);

        // Fetch presence data for today
        const fetchPresenceData = async () => {
            try {
                const response = await fetch(API_ENDPOINTS.TODAY_PRESENCE, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setPresenceData(data);
            } catch (err) {
                console.error('Error fetching presence data:', err);
                setError('Gagal memuat data presensi.');
            }
        };

        fetchPresenceData();
    }, []);

    useEffect(() => {
        // Get the user's location when the component mounts
        const getLocation = () => {
            if (navigator.geolocation) {
                const options = {
                    enableHighAccuracy: true, // Mencoba menggunakan GPS untuk akurasi terbaik
                    timeout: 10000,           // Batas waktu 10 detik untuk mendapatkan lokasi
                    maximumAge: 0             // Tidak menggunakan data lokasi yang di-cache
                };

                const watchId = navigator.geolocation.watchPosition(
                    (position) => {
                        setLatitude(position.coords.latitude);
                        setLongitude(position.coords.longitude);
                        setIsCalculatingDistance(false); // Menghentikan loading saat lokasi diperoleh
                    },
                    (error) => {
                        console.error('Error getting location:', error);
                        setError('Gagal mendapatkan lokasi.');
                        setIsCalculatingDistance(false); // Menghentikan loading jika terjadi kesalahan
                    },
                    options
                );

                // Cleanup on component unmount
                return () => navigator.geolocation.clearWatch(watchId);
            } else {
                setError('Geolocation tidak didukung oleh browser ini.');
                setIsCalculatingDistance(false);
            }
        };

        getLocation();
    }, []);

    useEffect(() => {
        // Calculate distance whenever latitude or longitude changes
        if (latitude && longitude) {
            calculateDistance(latitude, longitude);
        }
    }, [latitude, longitude]);

    useEffect(() => {
        // Function to update date and time
        const updateDateTime = () => {
            const now = new Date();
            const options = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: false,
            };
            const formattedDateTime = now.toLocaleString('id-ID', options);
            setCurrentDateTime(formattedDateTime);
        };

        updateDateTime(); // Initial call to set date and time
        const intervalId = setInterval(updateDateTime, 60000); // Update every minute

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, []);

    const calculateDistance = (lat1, lon1) => {
        if (window.google && window.google.maps) {
            const pointA = new window.google.maps.LatLng(lat1, lon1);
            const pointB = new window.google.maps.LatLng(referenceLat, referenceLon);
            const distanceInMeters = window.google.maps.geometry.spherical.computeDistanceBetween(pointA, pointB);
            setDistance(distanceInMeters / 1000); // Convert to kilometers
            setIsCalculatingDistance(false);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/login');
    };

    const handleOtpChange = (e) => {
        setOtp(e.target.value);
    };

    const handleAddPresensi = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        // Validasi jarak
        if (distance > 0.3) { // 0.3 km = 300 meter
            setError('Anda terlalu jauh dari lokasi yang ditentukan untuk melakukan presensi.');
            return;
        }

        try {
            const response = await fetch(API_ENDPOINTS.ADD_PRESENCE, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ otp, latitude, longitude }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (data.success) {
                setSuccess(data.message);
            } else {
                setError(data.message || 'Gagal menambahkan presensi.');
            }
        } catch (err) {
            setError('Terjadi kesalahan saat menambahkan presensi.');
            console.error('Error:', err);
        }
    };

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading...</div>;

    return (
        <div className="dashboard-container">
            <div className="dashboard-banner">
                <h2>Dashboard</h2>
                <img src="/logosmkbk.png" alt="Logo" className="logo" />
            </div>

            <p>Selamat datang {user ? user.nama : 'di dashboard'}!</p>
            <p>{currentDateTime}</p> {/* Menampilkan jam dan menit saat ini */}

            {/* Display presence data if available */}
            {presenceData ? (
                presenceData.data ? (
                    <div>
                        {/* Display presence data */}
                        <h3>Data Presensi:</h3>
                        <p>Tanggal: {presenceData.data.tanggal}</p>
                        <p>Jam Masuk: {presenceData.data.jam_masuk}</p>
                        <p>Jam Pulang: {presenceData.data.jam_pulang}</p>
                        <p>Keterlambatan: {presenceData.data.keterlambatan_menit} menit</p>
                    </div>
                ) : (
                    <div>
                        <h3>Belum melakukan presensi</h3>
                    </div>
                )
            ) : (
                <p>Memuat data presensi...</p>
            )}

            {/* Display distance */}
            {isCalculatingDistance ? (
                <div>Memproses jarak...</div>
            ) : (
                distance !== null && (
                    <div>
                        <h3>Jarak ke Sekolah:</h3>
                        <p>{distance.toFixed(2)} km</p>
                    </div>
                )
            )}

            {/* Presensi Form */}
            <div className="presensi-form">
                <h3>Tambah Presensi</h3>
                <form onSubmit={handleAddPresensi}>
                    <label>
                        OTP:
                        <input
                            type="text"
                            value={otp}
                            onChange={handleOtpChange}
                            required
                        />
                    </label>
                    <button type="submit">SubmitPresensi</button>
                </form>
                {error && <p className="error">{error}</p>}
                {success && <p className="success">{success}</p>}
            </div>

            <button onClick={handleLogout}>Logout</button>
        </div>
    );
};

export default Dashboard;
