import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Login from './Login'; // Pastikan Anda sudah membuat komponen Login
import Dashboard from './Dashboard'; // Pastikan Anda sudah membuat komponen Dashboard

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </div>
    </Router>
  );
}

function Home() {
  return (
    <header className="App-header">
      <img src="/logosmkbk.png" alt="logo" />
      <p>
        Selamat Datang Di Aplikasi Presensi Guru SMK Bhakti Kencana Bandung.
      </p>
      <Link to="/login" className="App-link">
        Login
      </Link>
    </header>
  );
}

export default App;
