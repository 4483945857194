// src/Login.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Tambahkan CSS styling jika diperlukan
import API_ENDPOINTS from './config'; // Import API endpoints

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(''); // Reset error state

        try {
            const response = await fetch(API_ENDPOINTS.GURU_LOGIN, { // Gunakan endpoint dari konfigurasi
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (data.success) {
                // Store token and user data
                localStorage.setItem('token', data.authorisation.token);
                localStorage.setItem('user', JSON.stringify(data.user));
    
                alert('Login berhasil!');
                navigate('/dashboard'); // Ganti dengan route yang sesuai
            } else {
                setError(data.message);
            }
        } catch (err) {
            setError('Terjadi kesalahan, coba lagi.');
            console.error('Error:', err);
        }
    };

    return (
        <div className="login-container">
            <h2>Login</h2>
            {error && <div className="error-message">{error}</div>}
            <form onSubmit={handleLogin}>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default Login;
